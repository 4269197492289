<template>
  <div>
    <div class="table-container">
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th>ID</th>
            <th>Descrição</th>
            <th v-show="!hideQtd">Qtd. mínima</th>
            <th v-show="!hideQtd">Qtd. máxima</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-if="!produtosSelecionados.length">
            <td colspan="5" style="text-align: center;">Nenhum produto selecionado</td>
          </tr>
          <tr v-for="(i, index) in produtosSelecionados">
            <td>{{ i.produto_id }}</td>
            <td>{{ i.descricao }}</td>
            <td v-show="!hideQtd">
              <input class="form-control" v-model="i.qtd_min" maxlength="30" type="number"/>
            </td>
            <td v-show="!hideQtd">
              <input class="form-control" v-model="i.qtd_max" maxlength="30" type="number"/>
            </td>
            <td>
              <button class="button button-error"
                      title="Remover"
                      v-on:click="()=>deselectItem(i, index)"
              >
                <strong>&times;</strong>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <label>
      <strong>Selecione os produtos na lista abaixo:</strong>
    </label>
    <div class="search-select">
      <input class="form-control" placeholder="Pesquisar por ID ou Descrição" type="text" v-model="searchQuery"/>
      <ul class="list">
        <li class="list-item" v-for="item in filteredItems" v-on:click="() => selectItem(item)">
          <div style="display: flex; align-items: center; justify-content: space-between; margin-right: 50px;">
                        <span>
                            {{ item.id }} - {{ item.descComp | truncate(80) }}
                        </span>
          </div>
          <button class="button button-primary add-item" title="Adicionar">
            &plus;
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.table-container {
  max-height: 230px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.table tbody > tr:nth-child(even) td {
  background-color: #fff;
}

.table tbody > tr:nth-child(odd) td {
  background-color: #f1f1f1;
}

.table tbody > tr > td {
  padding: 6px;
}

.list {
  padding: 10px 0;
  height: 200px;
  overflow-y: auto;
}

.list-item {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  position: relative;
}

.list-item:hover {
  background-color: #ddd !important;
}

.list-item:nth-child(odd) {
  background-color: #f1f1f1;
}

.list-item:nth-child(even) {
  font-size: 14px;
}

.add-item {
  position: absolute;
  right: 8px;
  top: 6px;
  padding: 1px 5px;
}

.input-desconto {
  width: 100px;
  border: 1px solid #999999;
  border-radius: 4px;
  text-align: right;
}
</style>

<script>
import _filter from 'lodash/filter';
import _some from 'lodash/some';

import {normalizeString, isNumeric} from '@/helpers/utils';

export default {
  name: 'multiselectable',
  props: [
    'regra',
    'produtos',
    'produtosSelecionados',
    'selectCallback',
    'deselectCallback',
    'hideQtd',
  ],
  data() {
    return {
      searchQuery: '',
      selectedItems: [],
    };
  },
  computed: {
    filteredItems() {
      if (this.searchQuery === '') {
        return this.diffItems(this.produtos);
      }
      if (isNumeric(this.searchQuery)) {
        const searchItems = _filter(this.produtos, (item) => {
          return +item.id === +this.searchQuery || +item.codBar;
        });
        return this.diffItems(searchItems);
      }
      const searchItems = _filter(this.produtos, (item) => {
        return (
          normalizeString(item.descComp)
            .toLowerCase()
            .indexOf(normalizeString(this.searchQuery).toLowerCase()) >= 0
        );
      });
      return this.diffItems(searchItems);
    },
  },
  methods: {
    selectItem(item) {
      const newItem = {
        produto_id: item.id,
        descricao: item.descComp,
        qtd_min: item.qtd_min || 1,
        qtd_max: item.qtd_max || 1,
      };

      this.selectedItems.push(item);
      this.selectCallback(newItem);
    },
    deselectItem(item, index) {
      this.selectedItems.splice(index, 1);
      this.deselectCallback(item['produto_id']);
    },
    diffItems(items) {
      return _filter(items, (item) => {
        return !_some(
          this.selectedItems,
          (selected) => selected.id === item.id
        );
      }).slice(0, 15);
    },
    reloadItems() {
      this.selectedItems = _filter(this.produtosSelecionados, (item) => {
        return _some(this.produtosSelecionados, (selected) => selected.id === item.id);
      }).slice(0, 15);
    },
  },
  filters: {
    truncate: (text, stop, clamp) => {
      return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '');
    },
  },
  created() {
    this.reloadItems();
  },
};
</script>
