<template>
  <modal name="editRegraNegocio" height="auto" width="900" :scrollable="true" :clickToClose="false"
         @before-open="loadDependencias">
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Informações">
            <Informacao
              :item="item"
              :validation="validation"
            />
          </CTab>
          <CTab title="Regras">
            <Regra
              :item="item"
              :produtos="produtos"
              :dimensoes="dimensoes"
              :promocoes="promocoes"
              :validation="validation"
            />
          </CTab>
          <CTab title="Restrições">
            <Restricao
                :item="item"
                :validation="validation"
            />
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <button :class="{ 'button button-success': true, 'button-disabled': validation.hasError() }"
              v-on:click="handleSubmit"
      >
        Salvar Alterações
      </button>
      <button class="button" v-on:click="close">Fechar</button>
    </div>
  </modal>
</template>

<script>

import SimpleVueValidation from "simple-vue-validator";
import Informacao from "./components/Informacao/Informacao";
import Regra from "./components/Regra/Regra";
import Restricao from "./components/Restricao/Restricao";
import {get} from "@/helpers/apiRequest";
import moment from "moment";

const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: " Preenchimento obrigatório",
    greaterThan: " Valor deve ser maior que 0",
  },
});

export default {
  name: 'editRegraNegocio',
  props: ['item', 'close', 'create', 'update'],
  data() {
    return {
      produtos: [],
      dimensoes: [],
      promocoes: [],
    };
  },
  validators: {
    "item.descricao": (value) => Validator.value(value).required(),
    "item.data_inicial": (value) => Validator.value(value).required(),
    "item.data_final": (value) => Validator.value(value).required(),
    "item.tipo": (value) => Validator.value(value).required(),
  },
  components: {
    Informacao,
    Regra,
    Restricao
  },
  methods: {
    handleSubmit() {
      this.$validate().then((success) => {
        if (success) {
          if (this.item.id) {
            this.update(this.normalizeItem(this.item));
            return;
          }

          this.create(this.normalizeItem(this.item));
        }
      });
    },
    loadDependencias() {
      this.loadDimensoes();
      this.loadProdutos();
      this.loadPromocoes();
      this.validation.reset();
    },
    loadDimensoes() {
      this.loading = true;

      get("/admin/lista-dimensoes")
        .then((json) => {
          this.dimensoes = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadProdutos() {
      this.loading = true;

      get("/admin/lista-produtos")
        .then((json) => {
          this.produtos = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    loadPromocoes() {
      this.loading = true;

      get("/admin/lista-promocoes")
        .then((json) => {
          this.promocoes = json;
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    normalizeItem(item) {
      item.data_inicial = item.data_inicial
        ? moment(item.data_inicial).format("YYYY-MM-DD")
        : null;
      item.data_final = item.data_final
        ? moment(item.data_final).format("YYYY-MM-DD")
        : null;

      return item;
    },
  }
};
</script>
