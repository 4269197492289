<template>
  <div class="xrow">
    <h2>
      Restrições Selecionadas:
    </h2>
    <br/>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>#</th>
          <th>Tipo</th>
          <th>Valor</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-if="!item.restricoes.length">
          <td colspan="4" style="text-align: center;">Adicionar Restrição</td>
        </tr>
        <tr v-for="(restricaoItem, index) in item.restricoes">
          <td>{{ index + 1 }}</td>
          <td>{{ restricaoItem.tipo }}</td>
          <td>{{ restricaoItem.chave }}</td>
          <td>
            <button class="button button-error"
                    title="Remover"
                    v-on:click="()=>removeRestricao(index)"
            >
              <strong>&times;</strong>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <h2>Adicionar Restrição:</h2>
    <div class="col-1-4">
      <div class="form-group">
        <label>Tipo:</label>
        <label>
          <select class="form-control" v-model="restricao.tipo">
            <option value="FILIAL">Filial</option>
            <option value="CLIENTE">Cliente</option>
            <option value="REGIAO">Região (Tabela de Preço)</option>
            <option value="REGIAO_RCA">Região (Representante)</option>
            <option value="REDE">Rede</option>
            <option value="PRACA">Praça</option>
            <option value="ATIVIDADE">Atividade</option>
            <option value="REPRESENTANTE">Representante</option>
            <option value="SUPERISOR">Supervisor</option>
            <option value="UF">UF</option>
          </select>
        </label>
      </div>
    </div>
    <div class="col-1-4">
      <div class="form-group">
        <div class="input-group">
          <label>{{ exibeLabelRestricao }}</label>
          <label>
            <input class="form-control" maxlength="250" v-model="restricao.chave">
            <button class="button button-primary input-group-addon-right"
                    :disabled="disableRestricaoAdd()"
                    title="Adicionar"
                    v-on:click="addRestricao"
            >
              +
            </button>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _find from 'lodash/find';

export default {
  name: 'Restricao',
  props: ['item'],
  watch: {
    'restricao.tipo'() {
      this.restricao.chave = '';
    },
  },
  computed: {
    exibeLabelRestricao() {
      switch (this.restricao.tipo) {
        case 'FILIAL':
          return 'Código filial:';
        case 'CLIENTE':
          return 'Código cliente:';
        case 'REGIAO':
          return 'Código região:';
        case 'REGIAO_RCA':
          return 'Código região:';
        case 'REDE':
          return 'Código rede:';
        case 'PRACA':
          return 'Código praça:';
        case 'ATIVIDADE':
          return 'Código atividade:';
        case 'REPRESENTANTE':
          return 'Código RCA:';
        case 'SUPERISOR':
          return 'Código supervisor:';
        case 'UF':
          return 'Código UF:';
        default:
          return 'Código:';
      }
    },
  },
  data() {
    return {
      restricao: {
        tipo: '',
        chave: '',
      },
    };
  },
  methods: {
    addRestricao() {
      this.item.restricoes.push({...this.restricao});
      this.resetForm();
    },
    removeRestricao(i) {
      this.item.restricoes.splice(i, 1);
    },
    resetForm() {
      this.restricao.tipo = '';
      this.restricao.chave = '';
    },
    disableRestricaoAdd() {
      return !this.restricao.chave || _find(this.item.restricoes, {
        tipo: this.restricao.tipo,
        chave: this.restricao.chave,
      });
    },
  },
};
</script>
